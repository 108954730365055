/* src/components/Form.css */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
}

.form-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

/* Center text in SubmitButton component */
.submit-button {
  text-align: center;
}

.form-title {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Replace with your desired font */
  font-size: 24px;
  margin-bottom: 20px;
}

.error-text {
  color: red;
  font-size: 14px;
  margin: 5px 0;
}

/* Style for label of DropdownMenu */
.dropdown-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.add-button {
  position: fixed;
  bottom: 20px;
  right: 600px;
  background: transparent; /* Make the button transparent */
  border: none; /* Remove border */
  cursor: pointer;
  z-index: 999; /* Ensure it appears above other content */
  color: transparent;
}

.popup-container {
  position: absolute;
  bottom: 50px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  width: 300px; /* Set the width of the popup */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 999; /* Ensure it appears above other content */
  display: black; /* Initially hide the popup */
}

/* Style the popup content */
.popup-content {
  max-height: 500px; /* Set the maximum height for the content */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

/* Add spacing and styling to the popup content */
.popup-content label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.popup-content input[type="text"],
.popup-content input[type="password"] {
  width: 80%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Style success and error messages */
.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.popup {
position: fixed;
top: 20px; /* Adjust the top position as needed */
left: 20px; /* Adjust the left position as needed */
padding: 10px;
border-radius: 10px;
z-index: 9999;
font-weight: bold;
color: #fff; /* Text color */
text-align: center;
width: 200px; /* Adjust the width as needed */
}

/* Success Popup styles */
.success-popup {
background-color: #4caf50; /* Success color */
}

/* Error Popup styles */
.error-popup {
background-color: #f44336; /* Error color */
}

/* Animation for fade-in and fade-out */
.popup-entering,
.popup-exiting,
.popup-exited {
opacity: 0;
}

.popup-entered {
opacity: 0.5;
transition: opacity 2s;
}

.manage-reminders-button-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.manage-reminders-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.3); /* Slightly transparent white */
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px; /* Rounded corners */
  transition: background-color 0.3s ease;
  border: 0.5px solid black;
}

.manage-reminders-button:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Slightly more opaque white on hover */
  border: 0.5px solid black;
}

.reminder-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Drop shadow */
  z-index: 999; /* Ensure it appears above other content */
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Set a maximum width */
  height: 80%; /* Adjust height as needed */
  max-height: 600px; /* Set a maximum height */
}

.reminder-popup-content {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


.reminder-popup-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.reminder-close-button {
  cursor: pointer;
}

.reminder-centered {
  max-width: 300px;
}

.login-button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor style */
  transition: background-color 0.3s ease; /* Smooth transition effect */
  margin-top: 20px; /* Add some margin at the top */
}

.login-button:hover {
  background-color: #0056b3; /* Darker background color on hover */
}


.otp-centered {
  text-align: center;
}

/* Style for label of OTP input */
.otp-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.otp-input {
  height: 50px; /* Adjust height as needed */
  font-size: 20px; /* Adjust font size */
  text-align: center; /* Center text horizontally */
  border: 2px solid #ccc; /* Add border */
  border-radius: 8px; /* Add border radius */
  margin: 10px; /* Add margin */
  outline: none; /* Remove default focus outline */
  letter-spacing: 15px;
}

.otp-input:focus {
  border-color: #007bff; /* Change border color on focus */
}

/* Style verify OTP button */
.verify-otp-button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor style */
  transition: background-color 0.3s ease; /* Smooth transition effect */
  margin-top: 20px; /* Add some margin at the top */
}

.verify-otp-button:hover {
  background-color: #0056b3; /* Darker background color on hover */
}

.show-list-container {
  max-height: 400px; /* Set the maximum height of the container */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}


.show-list ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.show-list li {
  margin-bottom: 10px; /* Add margin between list items */
  /* Customize the appearance of list items as needed */
}

.show-list::-webkit-scrollbar {
  display: none; /* Hide the default scrollbar */
}

.show-list-item {
  cursor: pointer; /* Change cursor to pointer on hover */
  user-select: text; /* Allow text selection */
}

.show-list-item:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}


.show-list {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 400px; /* Set maximum height */
  padding-top: 30px;
  padding-right: 30px;
  
}

.show-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

.show-list-item:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.show-list-item button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.show-list-item button:hover {
  text-decoration: underline;
}

.unsubscribe-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.unsubscribe-popup {
  position: absolute;
  top: -30px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px;
  display: none;
  z-index: 999; /* Ensure the popup appears above other content */
}


.unsubscribe-button:hover .unsubscribe-popup {
  display: block;
}


.unsubscribe-icon {
  color: red; /* Set the color of the unsubscribe icon */
}

.disclaimer {
  display:flex ;
  justify-content: flex-end; /* Align content to the end (bottom) */
  position: fixed;
  bottom: 0; /* Position it at the bottom */
  left: -50;
  width: 800px; /* Take full width */
  z-index: 9999; /* Adjust as needed */
}

.cookie-disclaimer-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  color: white;
  max-width: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cookie-disclaimer-popup button {
  background-color: #fefffe96;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-disclaimer-popup button:hover {
  background-color: #27ae60;
}

.cookie-disclaimer-content {
  flex-grow: 1; /* Grow to fill remaining space */
}

.cookie-disclaimer-accept-btn {
  margin-left: 20px;
}

.unsubscribe-all-button {
  background-color: rgba(255, 255, 255, 0.3); /* Slightly transparent white */
  color: black;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px; /* Rounded corners */
  transition: background-color 0.3s ease;
  border: 0.5px solid black;
  display: flex;
  margin-left: 36%;
  margin-top: 10px;
}